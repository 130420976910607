define("discourse/plugins/discourse-local-dates/initializers/discourse-local-dates", ["exports", "discourse-common/lib/deprecated", "discourse-common/lib/get-owner", "discourse/lib/d-popover", "discourse/plugins/discourse-local-dates/lib/local-date-builder", "discourse/lib/plugin-api", "discourse/lib/show-modal", "discourse/lib/download-calendar", "discourse-common/lib/icon-library", "I18n"], function (_exports, _deprecated, _getOwner, _dPopover, _localDateBuilder, _pluginApi, _showModal, _downloadCalendar, _iconLibrary, _I18n) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.applyLocalDates = applyLocalDates;
  _exports.default = void 0;
  function applyLocalDates(dates, siteSettings) {
    if (!siteSettings.discourse_local_dates_enabled) {
      return;
    }
    const currentUserTZ = moment.tz.guess();
    dates.forEach(element => {
      const opts = buildOptionsFromElement(element, siteSettings);
      const localDateBuilder = new _localDateBuilder.default(opts, currentUserTZ).build();
      element.innerText = "";
      element.insertAdjacentHTML("beforeend", `
        <svg class="fa d-icon d-icon-globe-americas svg-icon" xmlns="http://www.w3.org/2000/svg">
          <use href="#globe-americas"></use>
        </svg>
        <span class="relative-time">${localDateBuilder.formated}</span>
      `);
      element.setAttribute("aria-label", localDateBuilder.textPreview);
      const classes = ["cooked-date"];
      if (localDateBuilder.pastEvent) {
        classes.push("past");
      }
      element.classList.add(...classes);
    });
  }
  function buildOptionsFromElement(element, siteSettings) {
    const opts = {};
    const dataset = element.dataset;
    if (_rangeElements(element).length === 2) {
      opts.duration = _calculateDuration(element);
    }
    opts.time = dataset.time;
    opts.date = dataset.date;
    opts.recurring = dataset.recurring;
    opts.timezones = (dataset.timezones || siteSettings.discourse_local_dates_default_timezones || "Etc/UTC").split("|").filter(Boolean);
    opts.timezone = dataset.timezone;
    opts.calendar = (dataset.calendar || "on") === "on";
    opts.displayedTimezone = dataset.displayedTimezone;
    opts.format = dataset.format || (opts.time ? "LLL" : "LL");
    opts.countdown = dataset.countdown;
    return opts;
  }
  function _rangeElements(element) {
    if (!element.parentElement) {
      return [];
    }

    // TODO: element.parentElement.children.length !== 2 is a fallback to old solution for ranges
    // Condition can be removed after migration to [date-range]
    if (element.dataset.range !== "true" && element.parentElement.children.length !== 2) {
      return [element];
    }
    return Array.from(element.parentElement.children).filter(span => span.dataset.date);
  }
  function initializeDiscourseLocalDates(api) {
    const siteSettings = api.container.lookup("site-settings:main");
    const chat = api.container.lookup("service:chat");
    const defaultTitle = _I18n.default.t("discourse_local_dates.default_title", {
      site_name: siteSettings.title
    });
    if (chat) {
      chat.addToolbarButton({
        title: "discourse_local_dates.title",
        id: "local-dates",
        icon: "calendar-alt",
        action: "insertDiscourseLocalDate"
      });
      api.modifyClass("component:chat-composer", {
        pluginId: "discourse-local-dates",
        actions: {
          insertDiscourseLocalDate() {
            const insertDate = this.addText.bind(this);
            (0, _showModal.default)("discourse-local-dates-create-modal").setProperties({
              insertDate
            });
          }
        }
      });
    }
    api.decorateCookedElement((elem, helper) => {
      const dates = elem.querySelectorAll(".discourse-local-date");
      applyLocalDates(dates, siteSettings);
      const topicTitle = helper?.getModel()?.topic?.title;
      dates.forEach(date => {
        date.dataset.title = date.dataset.title || topicTitle || defaultTitle;
      });
    }, {
      id: "discourse-local-date"
    });
    api.onToolbarCreate(toolbar => {
      toolbar.addButton({
        title: "discourse_local_dates.title",
        id: "local-dates",
        group: "extras",
        icon: "calendar-alt",
        sendAction: event => toolbar.context.send("insertDiscourseLocalDate", event)
      });
    });
    api.modifyClass("component:d-editor", {
      pluginId: "discourse-local-dates",
      actions: {
        insertDiscourseLocalDate(toolbarEvent) {
          (0, _showModal.default)("discourse-local-dates-create-modal").setProperties({
            insertDate: markup => {
              toolbarEvent.addText(markup);
            }
          });
        }
      }
    });
  }
  function buildHtmlPreview(element, siteSettings) {
    const opts = buildOptionsFromElement(element, siteSettings);
    const localDateBuilder = new _localDateBuilder.default(opts, moment.tz.guess()).build();
    const htmlPreviews = localDateBuilder.previews.map(preview => {
      const previewNode = document.createElement("div");
      previewNode.classList.add("preview");
      if (preview.current) {
        previewNode.classList.add("current");
      }
      const timezoneNode = document.createElement("span");
      timezoneNode.classList.add("timezone");
      timezoneNode.innerText = preview.timezone;
      previewNode.appendChild(timezoneNode);
      const dateTimeNode = document.createElement("span");
      dateTimeNode.classList.add("date-time");
      dateTimeNode.innerHTML = preview.formated;
      previewNode.appendChild(dateTimeNode);
      return previewNode;
    });
    const previewsNode = document.createElement("div");
    previewsNode.classList.add("locale-dates-previews");
    htmlPreviews.forEach(htmlPreview => previewsNode.appendChild(htmlPreview));
    const calendarNode = _downloadCalendarNode(element);
    if (calendarNode) {
      previewsNode.appendChild(calendarNode);
    }
    return previewsNode.outerHTML;
  }
  function calculateStartAndEndDate(startDataset, endDataset) {
    let startDate, endDate;
    startDate = moment.tz(`${startDataset.date} ${startDataset.time || ""}`.trim(), startDataset.timezone);
    if (endDataset) {
      endDate = moment.tz(`${endDataset.date} ${endDataset.time || ""}`.trim(), endDataset.timezone);
    }
    return [startDate, endDate];
  }
  function _downloadCalendarNode(element) {
    const [startDataset, endDataset] = _rangeElements(element).map(dateElement => dateElement.dataset);
    const [startDate, endDate] = calculateStartAndEndDate(startDataset, endDataset);
    if (startDate < moment().tz(startDataset.timezone)) {
      return false;
    }
    const node = document.createElement("div");
    node.classList.add("download-calendar");
    node.innerHTML = `${(0, _iconLibrary.renderIcon)("string", "file")} ${_I18n.default.t("download_calendar.add_to_calendar")}`;
    node.setAttribute("data-starts-at", startDate.toISOString());
    if (endDataset) {
      node.setAttribute("data-ends-at", endDate.toISOString());
    }
    if (!startDataset.time && !endDataset) {
      node.setAttribute("data-ends-at", startDate.add(24, "hours").toISOString());
    }
    node.setAttribute("data-title", startDataset.title);
    return node;
  }
  function _calculateDuration(element) {
    const [startDataset, endDataset] = _rangeElements(element).map(dateElement => dateElement.dataset);
    const startDateTime = moment(`${startDataset.date} ${startDataset.time || ""}`.trim());
    const endDateTime = moment(`${endDataset.date} ${endDataset.time || ""}`.trim());
    const duration = endDateTime.diff(startDateTime, "minutes");

    // negative duration is used when we calculate difference for end date from range
    return element.dataset === startDataset ? duration : -duration;
  }
  var _default = _exports.default = {
    name: "discourse-local-dates",
    showDatePopover(event) {
      const owner = (0, _getOwner.getOwner)(this);
      if (owner.isDestroyed || owner.isDestroying) {
        return;
      }
      const siteSettings = owner.lookup("site-settings:main");
      if (event?.target?.classList?.contains("discourse-local-date")) {
        if ($(document.getElementById("d-popover"))[0]) {
          (0, _dPopover.hidePopover)(event);
        } else {
          (0, _dPopover.showPopover)(event, {
            htmlContent: buildHtmlPreview(event.target, siteSettings)
          });
        }
      } else if (event?.target?.classList?.contains("download-calendar")) {
        const dataset = event.target.dataset;
        (0, _dPopover.hidePopover)(event);
        (0, _downloadCalendar.downloadCalendar)(dataset.title, [{
          startsAt: dataset.startsAt,
          endsAt: dataset.endsAt
        }]);
      } else {
        (0, _dPopover.hidePopover)(event);
      }
    },
    hideDatePopover(event) {
      if (event?.target?.classList?.contains("discourse-local-date")) {
        (0, _dPopover.hidePopover)(event);
      }
    },
    initialize(container) {
      const router = container.lookup("router:main");
      router.on("routeWillChange", _dPopover.hidePopover);
      window.addEventListener("click", this.showDatePopover);
      const siteSettings = container.lookup("site-settings:main");
      if (siteSettings.discourse_local_dates_enabled) {
        $.fn.applyLocalDates = function () {
          (0, _deprecated.default)("`$.applyLocalDates()` is deprecated, import and use `applyLocalDates()` instead.");
          return applyLocalDates(this.toArray(), siteSettings);
        };
        (0, _pluginApi.withPluginApi)("0.8.8", initializeDiscourseLocalDates);
      }
    },
    teardown() {
      window.removeEventListener("click", this.showDatePopover);
    }
  };
});