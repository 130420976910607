define("discourse/plugins/discourse-local-dates/discourse/components/discourse-local-dates-create-form", ["exports", "discourse-common/utils/decorators", "@ember/component", "@ember/object", "I18n", "discourse-common/config/environment", "rsvp", "discourse/lib/text", "discourse-common/lib/debounce", "@ember/utils", "discourse/lib/load-script", "@ember/object/computed", "discourse/lib/computed", "@ember/runloop", "discourse-common/lib/get-owner"], function (_exports, _decorators, _component, _object, _I18n, _environment, _rsvp, _text, _debounce, _utils, _loadScript, _computed, _computed2, _runloop, _getOwner) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  /* global Pikaday:true */
  var _default = _exports.default = _component.default.extend(dt7948.p({
    timeFormat: "HH:mm:ss",
    dateFormat: "YYYY-MM-DD",
    dateTimeFormat: "YYYY-MM-DD HH:mm:ss",
    date: null,
    toDate: null,
    time: null,
    toTime: null,
    format: null,
    formats: null,
    recurring: null,
    advancedMode: false,
    timezone: null,
    fromSelected: null,
    fromFilled: (0, _computed.notEmpty)("date"),
    toSelected: null,
    toFilled: (0, _computed.notEmpty)("toDate"),
    init() {
      this._super(...arguments);
      this._picker = null;
      this.setProperties({
        timezones: [],
        formats: (this.siteSettings.discourse_local_dates_default_formats || "").split("|").filter(f => f),
        timezone: moment.tz.guess(),
        date: moment().format(this.dateFormat)
      });
    },
    didInsertElement() {
      this._super(...arguments);
      this._setupPicker().then(picker => {
        this._picker = picker;
        this.send("focusFrom");
      });
    },
    _renderPreview() {
      (0, _debounce.default)(this, function () {
        const markup = this.markup;
        if (markup) {
          (0, _text.cookAsync)(markup).then(result => {
            this.set("currentPreview", result);
            (0, _runloop.schedule)("afterRender", () => this.$(".preview .discourse-local-date").applyLocalDates());
          });
        }
      }, _environment.INPUT_DELAY);
    },
    isRange(date, toDate, toTime) {
      return date && (toDate || toTime);
    },
    isValid(config, isRange) {
      const fromConfig = config.from;
      if (!config.from.dateTime || !config.from.dateTime.isValid()) {
        return false;
      }
      if (isRange) {
        const toConfig = config.to;
        if (!toConfig.dateTime || !toConfig.dateTime.isValid() || toConfig.dateTime.diff(fromConfig.dateTime) < 0) {
          return false;
        }
      }
      return true;
    },
    fromConfig(date, time, isRange) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      const timeInferred = time ? false : true;
      let dateTime;
      if (!timeInferred) {
        dateTime = moment.tz(`${date} ${time}`, options.timezone);
      } else {
        dateTime = moment.tz(date, options.timezone);
      }
      if (!timeInferred) {
        time = dateTime.format(this.timeFormat);
      }
      let format = options.format;
      if (timeInferred && this.formats.includes(format)) {
        format = "LL";
      }
      return _object.default.create({
        date: dateTime.format(this.dateFormat),
        time,
        dateTime,
        format,
        range: isRange ? "start" : false
      });
    },
    toConfig(date, time, isRange) {
      let options = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : {};
      const timeInferred = time ? false : true;
      if (time && !date) {
        date = moment().format(this.dateFormat);
      }
      let dateTime;
      if (!timeInferred) {
        dateTime = moment.tz(`${date} ${time}`, options.timezone);
      } else {
        dateTime = moment.tz(date, options.timezone).endOf("day");
      }
      if (!timeInferred) {
        time = dateTime.format(this.timeFormat);
      }
      let format = options.format;
      if (timeInferred && this.formats.includes(format)) {
        format = "LL";
      }
      return _object.default.create({
        date: dateTime.format(this.dateFormat),
        time,
        dateTime,
        format,
        range: isRange ? "end" : false
      });
    },
    options(recurring, timezones, timezone, format) {
      return _object.default.create({
        recurring,
        timezones,
        timezone,
        format
      });
    },
    computedConfig(fromConfig, toConfig, options) {
      return _object.default.create({
        from: fromConfig,
        to: toConfig,
        options
      });
    },
    currentUserTimezone() {
      return moment.tz.guess();
    },
    allTimezones() {
      return moment.tz.names();
    },
    timezoneIsDifferentFromUserTimezone: (0, _computed2.propertyNotEqual)("currentUserTimezone", "options.timezone"),
    formatedCurrentUserTimezone(timezone) {
      return timezone.replace("_", " ").replace("Etc/", "").split("/");
    },
    previewedFormats(formats) {
      return formats.map(format => {
        return {
          format,
          preview: moment().format(format)
        };
      });
    },
    recurringOptions() {
      const key = "discourse_local_dates.create.form.recurring";
      return [{
        name: _I18n.default.t(`${key}.every_day`),
        id: "1.days"
      }, {
        name: _I18n.default.t(`${key}.every_week`),
        id: "1.weeks"
      }, {
        name: _I18n.default.t(`${key}.every_two_weeks`),
        id: "2.weeks"
      }, {
        name: _I18n.default.t(`${key}.every_month`),
        id: "1.months"
      }, {
        name: _I18n.default.t(`${key}.every_two_months`),
        id: "2.months"
      }, {
        name: _I18n.default.t(`${key}.every_three_months`),
        id: "3.months"
      }, {
        name: _I18n.default.t(`${key}.every_six_months`),
        id: "6.months"
      }, {
        name: _I18n.default.t(`${key}.every_year`),
        id: "1.years"
      }];
    },
    _generateDateMarkup(fromDateTime, options, isRange, toDateTime) {
      let text = ``;
      if (isRange) {
        let from = [fromDateTime.date, fromDateTime.time].filter(element => !(0, _utils.isEmpty)(element)).join("T");
        let to = [toDateTime.date, toDateTime.time].filter(element => !(0, _utils.isEmpty)(element)).join("T");
        text += `[date-range from=${from} to=${to}`;
      } else {
        text += `[date=${fromDateTime.date}`;
      }
      if (fromDateTime.time && !isRange) {
        text += ` time=${fromDateTime.time}`;
      }
      if (fromDateTime.format && fromDateTime.format.length) {
        text += ` format="${fromDateTime.format}"`;
      }
      if (options.timezone) {
        text += ` timezone="${options.timezone}"`;
      }
      if (options.timezones && options.timezones.length) {
        text += ` timezones="${options.timezones.join("|")}"`;
      }
      if (options.recurring && !isRange) {
        text += ` recurring="${options.recurring}"`;
      }
      text += `]`;
      return text;
    },
    toggleModeBtnLabel(advancedMode) {
      return advancedMode ? "discourse_local_dates.create.form.simple_mode" : "discourse_local_dates.create.form.advanced_mode";
    },
    markup(config, options, isValid, isRange) {
      let text;
      if (isValid && config.from) {
        if (config.to && config.to.range) {
          text = this._generateDateMarkup(config.from, options, isRange, config.to);
        } else {
          text = this._generateDateMarkup(config.from, options, isRange);
        }
      }
      return text;
    },
    formattedFrom(dateTime) {
      return dateTime.format("LLLL");
    },
    formattedTo(dateTime, toSelected) {
      const emptyText = toSelected ? "&nbsp;" : _I18n.default.t("discourse_local_dates.create.form.until");
      return dateTime.isValid() ? dateTime.format("LLLL") : emptyText;
    },
    actions: {
      setTime(event) {
        this._setTimeIfValid(event.target.value, "time");
      },
      setToTime(event) {
        this._setTimeIfValid(event.target.value, "toTime");
      },
      eraseToDateTime() {
        this.setProperties({
          toDate: null,
          toTime: null
        });
        this._setPickerDate(null);
      },
      focusFrom() {
        this.setProperties({
          fromSelected: true,
          toSelected: false
        });
        this._setPickerDate(this.get("fromConfig.date"));
        this._setPickerMinDate(null);
      },
      focusTo() {
        this.setProperties({
          toSelected: true,
          fromSelected: false
        });
        this._setPickerDate(this.get("toConfig.date"));
        this._setPickerMinDate(this.get("fromConfig.date"));
      },
      advancedMode() {
        this.toggleProperty("advancedMode");
      },
      save() {
        const markup = this.markup;
        if (markup) {
          this._closeModal();
          this.insertDate(markup);
        }
      },
      cancel() {
        this._closeModal();
      }
    },
    _setTimeIfValid(time, key) {
      if ((0, _utils.isEmpty)(time)) {
        this.set(key, null);
        return;
      }
      if (/^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/.test(time)) {
        this.set(key, time);
      }
    },
    _setupPicker() {
      return new _rsvp.Promise(resolve => {
        (0, _loadScript.default)("/javascripts/pikaday.js").then(() => {
          const options = {
            field: this.$(`.fake-input`)[0],
            container: this.$(`#picker-container-${this.elementId}`)[0],
            bound: false,
            format: "YYYY-MM-DD",
            reposition: false,
            firstDay: 1,
            setDefaultDate: true,
            keyboardInput: false,
            i18n: {
              previousMonth: _I18n.default.t("dates.previous_month"),
              nextMonth: _I18n.default.t("dates.next_month"),
              months: moment.months(),
              weekdays: moment.weekdays(),
              weekdaysShort: moment.weekdaysMin()
            },
            onSelect: date => {
              const formattedDate = moment(date).format("YYYY-MM-DD");
              if (this.fromSelected) {
                this.set("date", formattedDate);
              }
              if (this.toSelected) {
                this.set("toDate", formattedDate);
              }
            }
          };
          resolve(new Pikaday(options));
        });
      });
    },
    _setPickerMinDate(date) {
      if (date && !moment(date, this.dateFormat).isValid()) {
        date = null;
      }
      (0, _runloop.schedule)("afterRender", () => {
        this._picker.setMinDate(moment(date, this.dateFormat).toDate());
      });
    },
    _setPickerDate(date) {
      if (date && !moment(date, this.dateFormat).isValid()) {
        date = null;
      }
      (0, _runloop.schedule)("afterRender", () => {
        this._picker.setDate(moment.utc(date), true);
      });
    },
    _closeModal() {
      const composer = (0, _getOwner.getOwner)(this).lookup("controller:composer");
      composer.send("closeModal");
    }
  }, [["method", "_renderPreview", [(0, _decorators.observes)("markup")]], ["method", "isRange", [(0, _decorators.default)("date", "toDate", "toTime")]], ["method", "isValid", [(0, _decorators.default)("computedConfig", "isRange")]], ["method", "fromConfig", [(0, _decorators.default)("date", "time", "isRange", "options.{format,timezone}")]], ["method", "toConfig", [(0, _decorators.default)("toDate", "toTime", "isRange", "options.{timezone,format}")]], ["method", "options", [(0, _decorators.default)("recurring", "timezones", "timezone", "format")]], ["method", "computedConfig", [(0, _decorators.default)("fromConfig.{date}", "toConfig.{date}", "options.{recurring,timezones,timezone,format}")]], ["method", "currentUserTimezone", [_decorators.default]], ["method", "allTimezones", [_decorators.default]], ["method", "formatedCurrentUserTimezone", [(0, _decorators.default)("currentUserTimezone")]], ["method", "previewedFormats", [(0, _decorators.default)("formats")]], ["method", "recurringOptions", [_decorators.default]], ["method", "toggleModeBtnLabel", [(0, _decorators.default)("advancedMode")]], ["method", "markup", [(0, _decorators.default)("computedConfig.{from,to,options}", "options", "isValid", "isRange")]], ["method", "formattedFrom", [(0, _decorators.default)("fromConfig.dateTime")]], ["method", "formattedTo", [(0, _decorators.default)("toConfig.dateTime", "toSelected")]]]));
});